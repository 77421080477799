import UserSession from './models/user-session.js';

const DATE_TIMES = {
  millisecondsIn1Hour: 1 * 60 * 60 * 1000,
  millisecondsIn1Day: 1000 * 3600 * 24,
};

const FEATURES = {
  readonly: 'Read only',
  marketing: 'Marketing',
  showUnApprovedLanguages: 'Show me Unapproved Languages',
  showUnsupportedCurrencies: 'Show Unsupported Currencies',
  skillOnboarding: 'Skills Onboarding',
  redirectToSkillsWave: 'Redirect to SkillsWave',
  performancePOC: 'Performance POC',
  showCompletionStatusExport: 'Show Completion Status Export',
  showProviderCompletionVerification: 'Show Provider Completion Verification',
};

const SUPPORT_CATEGORIES = {
  productFeedback: 'contact-support.form.questionType.academicContent',
  employerPolicy: 'contact-support.form.questionType.employerPolicy',
  academicContent: 'contact-support.form.questionType.productFeedback',
  technicalSupport: 'contact-support.form.questionType.technicalSupport',
};

const TIMEFRAME_TYPES = [
  { displayName: 'All Time', value: 'allTime' },
  { displayName: 'In last day', value: 'lastDay' },
  { displayName: 'In last week', value: 'lastWeek' },
  { displayName: 'In last month', value: 'lastMonth' },
  { displayName: 'In last year', value: 'lastYear' },
  { displayName: 'Since fiscal year', value: 'sinceFiscalYear' },
];

const BASE_DOMAINS = {
  dev: 'skillswave.dev',
  prod: 'skillswave.com',
};

const STATIC_ASSET_URLS = {
  dev: `https://static.${BASE_DOMAINS.dev}/assets/`,
  prod: `https://static.${BASE_DOMAINS.prod}/assets/`,
};

const COOKIE_NAME = 'wave.sess';

const DEFAULT_CURRENCY = 'CAD';

const SUPPORTED_COURSE_TYPES = [
  'course',
  'conference',
  'program',
  'textbook',
  'webinar',
  'workshop',
  'other',
];

const D2L_REVSHARE_TAX_REGISTRATIONS = {
  ON: ['Canada_hst_13'],
  NB: ['Canada_hst_15'],
  PE: ['Canada_hst_15'],
  NS: ['Canada_hst_15'],
  NL: ['Canada_hst_15'],
  BC: ['Canada_gst_5', 'British Columbia_pst_7'],
  SK: ['Canada_gst_5', 'Saskatchewan_pst_6'],
  MB: ['Canada_gst_5', 'Manitoba_rst_7'],
  OTHER: ['Canada_gst_5'],
};

// Supported currency codes and country names, from the Bank of Canada (ISO 4217 codes/names)
const SUPPORTED_CURRENCIES = {
  CAD: 'Canadian dollar',
  USD: 'United States dollar',
};

const TAXABLE_COUNTRIES = {
  CA: 'countries.canada',
  US: 'countries.united-states',
};

const ISO_3166_COUNTRY_CODES = {
  CA: 'CAN',
  US: 'USA',
};

// Supported country codes and names, for Stripe (ISO 3166-1 Alpha-2 codes/names)
const SUPPORTED_COUNTRIES = {
  ...TAXABLE_COUNTRIES,
  Other: 'countries.other',
};

const SUPPORTED_TRANSCRIPTS = {
  CA: 'countries.canada',
  INT: 'transcript.international',
};

const TAXABLE_US_STATES = [
  { name: 'Alabama', value: 'Alabama' },
  { name: 'Alaska', value: 'Alaska' },
  { name: 'Arizona', value: 'Arizona' },
  { name: 'Arkansas', value: 'Arkansas' },
  { name: 'California', value: 'California' },
  { name: 'Colorado', value: 'Colorado' },
  { name: 'Connecticut', value: 'Connecticut' },
  { name: 'Delaware', value: 'Delaware' },
  { name: 'District of Columbia', value: 'District of Columbia' },
  { name: 'Florida', value: 'Florida' },
  { name: 'Georgia', value: 'Georgia' },
  { name: 'Hawaii', value: 'Hawaii' },
  { name: 'Idaho', value: 'Idaho' },
  { name: 'Illinois', value: 'Illinois' },
  { name: 'Indiana', value: 'Indiana' },
  { name: 'Iowa', value: 'Iowa' },
  { name: 'Kansas', value: 'Kansas' },
  { name: 'Kentucky', value: 'Kentucky' },
  { name: 'Louisiana', value: 'Louisiana' },
  { name: 'Maine', value: 'Maine' },
  { name: 'Maryland', value: 'Maryland' },
  { name: 'Massachusetts', value: 'Massachusetts' },
  { name: 'Michigan', value: 'Michigan' },
  { name: 'Minnesota', value: 'Minnesota' },
  { name: 'Mississippi', value: 'Mississippi' },
  { name: 'Missouri', value: 'Missouri' },
  { name: 'Montana', value: 'Montana' },
  { name: 'Nebraska', value: 'Nebraska' },
  { name: 'Nevada', value: 'Nevada' },
  { name: 'New Hampshire', value: 'New Hampshire' },
  { name: 'New Jersey', value: 'New Jersey' },
  { name: 'New Mexico', value: 'New Mexico' },
  { name: 'New York', value: 'New York' },
  { name: 'North Carolina', value: 'North Carolina' },
  { name: 'North Dakota', value: 'North Dakota' },
  { name: 'Ohio', value: 'Ohio' },
  { name: 'Oklahoma', value: 'Oklahoma' },
  { name: 'Oregon', value: 'Oregon' },
  { name: 'Pennsylvania', value: 'Pennsylvania' },
  { name: 'Rhode Island', value: 'Rhode Island' },
  { name: 'South Carolina', value: 'South Carolina' },
  { name: 'South Dakota', value: 'South Dakota' },
  { name: 'Tennessee', value: 'Tennessee' },
  { name: 'Texas', value: 'Texas' },
  { name: 'Utah', value: 'Utah' },
  { name: 'Vermont', value: 'Vermont' },
  { name: 'Virginia', value: 'Virginia' },
  { name: 'Washington', value: 'Washington' },
  { name: 'West Virginia', value: 'West Virginia' },
  { name: 'Wisconsin', value: 'Wisconsin' },
  { name: 'Wyoming', value: 'Wyoming' },
];
const TAXABLE_CA_PROVINCES = [
  { name: 'PST - British Columbia', value: 'British Columbia' },
  { name: 'RST - Manitoba', value: 'Manitoba' },
  { name: 'PST - Saskatchewan', value: 'Saskatchewan' },
  { name: 'QST - Quebec', value: 'Quebec' },
];

// Supported budget holders
const SUPPORTED_BUDGET_HOLDERS = {
  manager: 'Manager',
  sponsor: 'Sponsor',
};

const TOS_VERSION = '1.0';

// Unsupported currencies, visible if showUnsupportedCurrencies flag is enabled for the tenant (ISO 4217 codes/names)
const UNSUPPORTED_CURRENCIES = {
  AUD: 'Australian dollar',
  BRL: 'Brazilian real',
  CHF: 'Swiss franc',
  CNY: 'Chinese yuan',
  EUR: 'Euro',
  GBP: 'Pound sterling',
  HKD: 'Hong Kong dollar',
  IDR: 'Indonesian rupiah',
  INR: 'Indian rupee',
  JPY: 'Japanese yen',
  KRW: 'South Korean won',
  MXN: 'Mexican peso',
  MYR: 'Malaysian ringgit',
  NOK: 'Norwegian krone',
  NZD: 'New Zealand dollar',
  PEN: 'Peruvian sol',
  RUB: 'Russian ruble',
  SAR: 'Saudi riyal',
  SEK: 'Swedish krona',
  SGD: 'Singapore dollar',
  THB: 'Thai baht',
  TRY: 'Turkish lira',
  TWD: 'New Taiwan dollar',
  VND: 'Vietnamese dong',
  ZAR: 'South African rand',
};

const SHOWCASE_MYLIST_ACTIVITIES = [
  '4c2a22d2-6d6c-4d3a-8813-0ce0ad804573',
  '349a8f37-582a-4374-9144-e5ee117d3184',
  '756A631F-4AB7-48E0-9829-5A5F61C73E33',
  '6D11E951-2103-4175-95FC-1C34277C5646',
];

const SUBMIT_MY_OWN_PROVIDER_ID = '00000000-e4fe-47af-8ff6-187bca92f3f9';
const SUBMIT_MY_OWN_ACTIVITY_ID = '11111111-e4fe-47af-8ff6-187bca92f3f9';

const WAVE_SYSTEM_USER = new UserSession({
  tenantId: '88888888-f381-4b6c-99df-7fba2f328030',
  userId: '99999999-f381-4b6c-99df-7fba2f328030',
  guid: '99999999-f381-4b6c-99df-7fba2f328030',
  tenantType: 'admin',
  entitlements: ['admin'],
});

const WAVE_DISASTER_RECOVERY_USER = new UserSession({
  tenantId: '00000000-3960-49e1-936f-151eaa3cace3',
  userId:'00000000-4f50-43c4-8236-004b5509183e',
  guid: '00000000-4f50-43c4-8236-004b5509183e',
  tenantType: 'admin',
  entitlements: ['admin'],
});

const LEARNER_OPTIONS = ['employee', 'member', 'jobseeker'];

const USER_DATA_SYSTEMS = ['IDP', 'HRIS'];

const AUTH0_USER_ROOT_ATTRIBUTES = new Set([
  'app_metadata',
  'blocked',
  'email',
  'email_verified',
  'family_name',
  'given_name',
  'name',
  'nickname',
  'password',
  'phone_number',
  'phone_verified',
  'picture',
  'username',
  'user_metadata',
  'verify_email',
]);

const AUDIT_OPTIONS = {
  minimumAuditDate: '2017-01-01T00:00:00.000Z',
  maximumAuditRangeDays: 90,
  allowedTimeframes: [
    ...TIMEFRAME_TYPES.filter(type => type.value !== 'allTime' && type.value !== 'lastYear'),
    { displayName: 'Custom', value: 'custom' },
  ],
};

const LANDING_STREAM_TYPES = {
  bestResults: 'best-results',
  microlearning: 'microlearning',
  courses: 'courses',
  shortCredentials: 'short-credentials',
  degrees: 'degrees',
  interestedGoals: 'interested-goals',
};

export {
  AUDIT_OPTIONS,
  AUTH0_USER_ROOT_ATTRIBUTES,
  BASE_DOMAINS,
  COOKIE_NAME,
  D2L_REVSHARE_TAX_REGISTRATIONS,
  DATE_TIMES,
  DEFAULT_CURRENCY,
  FEATURES,
  ISO_3166_COUNTRY_CODES,
  LANDING_STREAM_TYPES,
  LEARNER_OPTIONS,
  SHOWCASE_MYLIST_ACTIVITIES,
  STATIC_ASSET_URLS,
  SUBMIT_MY_OWN_ACTIVITY_ID,
  SUBMIT_MY_OWN_PROVIDER_ID,
  SUPPORTED_BUDGET_HOLDERS,
  SUPPORTED_COUNTRIES,
  SUPPORTED_COURSE_TYPES,
  SUPPORTED_CURRENCIES,
  SUPPORTED_TRANSCRIPTS,
  SUPPORT_CATEGORIES,
  TAXABLE_CA_PROVINCES,
  TAXABLE_COUNTRIES,
  TAXABLE_US_STATES,
  TIMEFRAME_TYPES,
  TOS_VERSION,
  UNSUPPORTED_CURRENCIES,
  USER_DATA_SYSTEMS,
  WAVE_SYSTEM_USER,
  WAVE_DISASTER_RECOVERY_USER
};
